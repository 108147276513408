<template>
  <div class="ui padded attached segment" id="content">
    <div class="ui stackable grid">
      <div class="row">
        <div class="eight wide column">
          <img
            src="../assets/images/accueil/cours-de-musique.svg"
            class="ui fluid image"
          />
        </div>
        <div class="eight wide column">
          <h1 class="ui header">Cours de musique</h1>
          <p>
            Venez découvrir, apprendre et jouer sur votre instrument favori
            <strong>à tout âge</strong> ! Nous vous proposerons
            <strong>méthodes et morceaux adaptés</strong> à vos goûts et votre
            niveau. Pour ceux qui le souhaitent, goûtez au
            <strong>plaisir</strong> de participer à un
            <strong>concours</strong>.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="three wide column">
          <img
            src="../assets/images/accueil/instruments.svg"
            class="ui fluid image"
          />
        </div>
        <div class="five wide column">
          <h1 class="ui header">Plusieurs instruments</h1>
          <p>
            Nous vous proposons des cours pour près d’une
            <strong>dizaine d’instruments différents</strong>, et ce
            <strong>quelque soit votre âge et votre niveau</strong>, en
            <strong>cours collectif ou individuel</strong>.
          </p>
        </div>
        <div class="three wide column">
          <img
            src="../assets/images/accueil/carte.svg"
            class="ui fluid image"
          />
        </div>
        <div class="five wide column">
          <h1 class="ui header">Plusieurs villes</h1>
          <p>
            Vous pouvez nous retrouver à <strong>Molsheim</strong>, ainsi qu'à
            <strong>Wisches</strong>.
          </p>
        </div>
      </div>
      <div class="ui divider"></div>
      <div class="centered row">
        <div class="ten wide column">
          <h1 class="ui header center aligned"><strong>Actualités</strong></h1>
          <router-link
            :to="{ name: 'FinalesAMF2023' }"
            style="color: rgba(0, 0, 0, 0.87)"
          >
            <div
              style="background-color: #ddd; padding: 10px; border-radius: 10px"
            >
              <h2 style="font-size: 18px">Finales 2023 à Chaville</h2>
              <p>
                Le 22 octobre 2023 se sont déroulées les finales du Trophée
                National. Plusieurs élèves de l'école y ont participé, après
                avoir obtenu de bons résultats au concours régional en mai
                dernier. 5 Trophées ont été gagnés par les élèves de l'école. En
                guitare électrique élémentaire 1, DROUOT Léane a obtenu un
                Trophée pour la 2ème année consécutive. En batterie, DENNI
                Baptiste a remporté également un Trophée en supérieur 1, après
                celui obtenu en 2019. En guitare classique, PEZECHKPOUR Maël a
                gagné son 1er Trophée en probatoire. Cette année, 2 Trophées
                pour des duos : en 4 mains piano élémentaire: FOURGEAUD Anna et
                Hélène, et en guitare préparatoire : SCHAEFFER Alessia et
                LARGENTIER Romane. Un grand merci à eux et à tous les autres
                élèves qui ont participé à ces finales 2023 pour leur beau
                travail !
              </p>
              <button class="ui red button">Voir les résultats</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
