<template>
  <div class="ui inverted red attached segment">
    <div class="ui two column divided inverted stackable grid">
      <div class="column">
        <h4 class="ui inverted header">
          <i class="envelope icon"></i>Contactez-nous
        </h4>
        <div class="ui inverted list">
          <div class="item">
            <i class="marker icon"></i>
            <div class="content">
              <strong class="header">École de Musique Muller</strong>
              <div class="description">
                43 route de Dachstein<br />
                67120 Molsheim
              </div>
            </div>
          </div>
        </div>
        <div class="ui inverted list">
          <div class="item">
            <i class="mail icon"></i>
            <div class="content">
              <a href="mailto:musique.chr.muller@gmail.com"
                >musique.chr.muller@gmail.com</a
              >
            </div>
          </div>
          <div class="item">
            <i class="phone icon"></i>
            <div class="content">
              <a href="tel:+33388493211">03 88 49 32 11</a>
            </div>
          </div>
          <div class="item">
            <i class="mobile icon"></i>
            <div class="content">
              <a href="tel:+33608647762">06 08 64 77 62</a>
            </div>
          </div>
        </div>
        <div class="ui inverted list">
          <div class="item">
            <i class="pencil icon"></i>
            <div class="content">
              <router-link :to="{ name: 'ContactezNous' }" class="item"
                >Contactez-nous</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <h4 class="ui inverted header">
          <i class="legal icon"></i>Mentions légales
        </h4>
        <div class="ui inverted relaxed list">
          <div class="item">
            <i class="file text icon"></i>
            <div class="content">
              <router-link :to="{ name: 'MentionsLegales' }"
                >Mentions légales</router-link
              ><br />
            </div>
          </div>
          <div class="ui inverted divider"></div>
          <div class="item">
            <i class="creative commons icon"></i>
            <div class="content">
              Le contenu du site est licencié sous
              <a href="https://creativecommons.org/licenses/by/4.0/"
                >Licence Creative Commons Attribution 4.0 International</a
              >.
            </div>
          </div>
          <div class="item">
            <i class="picture icon"></i>
            <div class="content">
              Les images du site proviennent de
              <a href="https://www.freepik.com/">Freepik</a>.
            </div>
          </div>
          <div class="item">
            <i class="user icon"></i>
            <div class="content">Site créé par Quentin Marlats.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
