<template>
  <PageHeader />
  <div class="ui justified container">
    <router-view />
    <PageFooter />
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: {
    PageFooter,
    PageHeader,
  },
  mounted() {
    const script = document.createElement("script");
    script.setAttribute("src", "scripts.js");
    document.head.appendChild(script);
  },
};
</script>
